import { Controller } from "@hotwired/stimulus"
import Pristine from "pristinejs"
import { loadStripe } from "@stripe/stripe-js/pure"

// Connects to data-controller="donations"
export default class extends Controller {
  static values = { selectedAmount: Number, elements: Object, stripe: Object }
  static targets = ["amount"]

  connect() {
    this.initStripe();
    this.setSelectedValue();
  }

  setSelectedValue() {
    const selectedAmount = document.querySelector("input[name='amount_options']:checked");
    this.selectedAmountValue = selectedAmount.value;
    document.querySelector("#other_amount").value = this.selectedAmountValue;
  }
 
  setOtherValue() {
    const selectedValue = document.querySelector("input[name='amount_options']:checked");
    if (selectedValue) {
      selectedValue.checked = false;
    }
    const enteredValue = document.querySelector("#other_amount").value;
    this.selectedAmountValue = enteredValue;
  }

  validateStep(e) {
    const pristineConfig = {
      classTo: "input-wrapper",
      errorClass: "has-error",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
    };
    const pristine = new Pristine(document.getElementById("payment-form"), pristineConfig);
    const currentStep = document.querySelector('.current-step');
    const inputs = currentStep.querySelectorAll('input');
    const valid = pristine.validate(inputs);

    if (!valid) {
      return;
    }
    this.goToStep(e);
  }

  goToStep(e) {
    e.preventDefault();
    e.stopPropagation();

    const currentStepEl = document.querySelector('.current-step');
    const targetStep = e.target.dataset.goTo;
    const targetStepEl = document.querySelector(`[data-step="${targetStep}"]`);

    currentStepEl.classList.remove('current-step');
    currentStepEl.classList.add('d-none');
    targetStepEl.classList.add('current-step');
    targetStepEl.classList.remove('d-none');

    if (targetStep == 3) {
      this.createPaymentIntent();
    }
  }

  async initStripe() {
    const stripeKey = document.querySelector("meta[name=stripe-key]").content;
    this.stripe = await loadStripe(stripeKey);
  }

  async createPaymentIntent() {
    this.setLoading(true);
    const authenticyToken = document.querySelector("meta[name=csrf-token]").content;
    const response = await fetch("/donations/create_payment_intent", {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRF-Token": authenticyToken },
      body: JSON.stringify({ amount: this.selectedAmountValue, email: document.querySelector("#email").value, name: document.querySelector("#name").value }),
    });
    const { clientSecret } = await response.json();

    const appearance = {
      theme: "stripe",
    };

    const wallets = {
      applePay: "auto",
      googlePay: "auto",
    };
    this.elements = this.stripe.elements({ appearance, clientSecret, wallets });

    const paymentElementOptions = {
      layout: "tabs",
    };

    const paymentElement = this.elements.create("payment", paymentElementOptions);
    const button = document.querySelector("#submit");
    const buttonAmount = button.querySelector("#button-text span");
    paymentElement.mount("#payment-element");
    buttonAmount.innerText = `$${this.selectedAmountValue}`;
    button.classList.remove("d-none");
    this.setLoading(false);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const elements = this.elements;
    const baseUrl = document.querySelector("meta[name=base-url]").content;

    this.setLoading(true);

    const { error } = await this.stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${baseUrl}/donations/thank_you`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occurred.");
    }
    this.setLoading(false);
  }

  showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("d-none");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("d-none");
      messageContainer.textContent = "";
    }, 4000);
  }

  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector(".spinner-wrapper").classList.remove("d-none");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector(".spinner-wrapper").classList.add("d-none");
    }
  }
}
